var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
class Range {
  constructor(start, end) {
    this.containsPosition = (position) => {
      if (this.start.line === position.line) {
        return this.start.character <= position.character;
      }
      if (this.end.line === position.line) {
        return this.end.character >= position.character;
      }
      return this.start.line <= position.line && this.end.line >= position.line;
    };
    this.start = start;
    this.end = end;
  }
  setStart(line, character) {
    this.start = new Position(line, character);
  }
  setEnd(line, character) {
    this.end = new Position(line, character);
  }
}
__name(Range, "Range");
class Position {
  constructor(line, character) {
    this.lessThanOrEqualTo = (position) => this.line < position.line || this.line === position.line && this.character <= position.character;
    this.line = line;
    this.character = character;
  }
  setLine(line) {
    this.line = line;
  }
  setCharacter(character) {
    this.character = character;
  }
}
__name(Position, "Position");
export { Position as P, Range as R };
