var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { C as CodeMirror } from "./codemirror.es.js";
import "graphql";
import { o as onlineParser, i as isIgnored, L as LexRules, P as ParseRules } from "./index.es.js";
import { i as indent } from "./mode-indent.es.js";
import "react";
import "react/jsx-runtime";
import "react-dom";
const graphqlModeFactory = /* @__PURE__ */ __name((config) => {
  const parser = onlineParser({
    eatWhitespace: (stream) => stream.eatWhile(isIgnored),
    lexRules: LexRules,
    parseRules: ParseRules,
    editorConfig: { tabSize: config.tabSize }
  });
  return {
    config,
    startState: parser.startState,
    token: parser.token,
    indent,
    electricInput: /^\s*[})\]]/,
    fold: "brace",
    lineComment: "#",
    closeBrackets: {
      pairs: '()[]{}""',
      explode: "()[]{}"
    }
  };
}, "graphqlModeFactory");
CodeMirror.defineMode("graphql", graphqlModeFactory);
