var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { a as codemirror } from "./codemirror.es.js";
function _mergeNamespaces(n, m) {
  m.forEach(function(e) {
    e && typeof e !== "string" && !Array.isArray(e) && Object.keys(e).forEach(function(k) {
      if (k !== "default" && !(k in n)) {
        var d = Object.getOwnPropertyDescriptor(e, k);
        Object.defineProperty(n, k, d.get ? d : {
          enumerable: true,
          get: function() {
            return e[k];
          }
        });
      }
    });
  });
  return Object.freeze(Object.defineProperty(n, Symbol.toStringTag, { value: "Module" }));
}
__name(_mergeNamespaces, "_mergeNamespaces");
var simplescrollbars$2 = { exports: {} };
(function(module, exports) {
  (function(mod) {
    mod(codemirror.exports);
  })(function(CodeMirror) {
    function Bar(cls, orientation, scroll) {
      this.orientation = orientation;
      this.scroll = scroll;
      this.screen = this.total = this.size = 1;
      this.pos = 0;
      this.node = document.createElement("div");
      this.node.className = cls + "-" + orientation;
      this.inner = this.node.appendChild(document.createElement("div"));
      var self = this;
      CodeMirror.on(this.inner, "mousedown", function(e) {
        if (e.which != 1)
          return;
        CodeMirror.e_preventDefault(e);
        var axis = self.orientation == "horizontal" ? "pageX" : "pageY";
        var start = e[axis], startpos = self.pos;
        function done() {
          CodeMirror.off(document, "mousemove", move);
          CodeMirror.off(document, "mouseup", done);
        }
        __name(done, "done");
        function move(e2) {
          if (e2.which != 1)
            return done();
          self.moveTo(startpos + (e2[axis] - start) * (self.total / self.size));
        }
        __name(move, "move");
        CodeMirror.on(document, "mousemove", move);
        CodeMirror.on(document, "mouseup", done);
      });
      CodeMirror.on(this.node, "click", function(e) {
        CodeMirror.e_preventDefault(e);
        var innerBox = self.inner.getBoundingClientRect(), where;
        if (self.orientation == "horizontal")
          where = e.clientX < innerBox.left ? -1 : e.clientX > innerBox.right ? 1 : 0;
        else
          where = e.clientY < innerBox.top ? -1 : e.clientY > innerBox.bottom ? 1 : 0;
        self.moveTo(self.pos + where * self.screen);
      });
      function onWheel(e) {
        var moved = CodeMirror.wheelEventPixels(e)[self.orientation == "horizontal" ? "x" : "y"];
        var oldPos = self.pos;
        self.moveTo(self.pos + moved);
        if (self.pos != oldPos)
          CodeMirror.e_preventDefault(e);
      }
      __name(onWheel, "onWheel");
      CodeMirror.on(this.node, "mousewheel", onWheel);
      CodeMirror.on(this.node, "DOMMouseScroll", onWheel);
    }
    __name(Bar, "Bar");
    Bar.prototype.setPos = function(pos, force) {
      if (pos < 0)
        pos = 0;
      if (pos > this.total - this.screen)
        pos = this.total - this.screen;
      if (!force && pos == this.pos)
        return false;
      this.pos = pos;
      this.inner.style[this.orientation == "horizontal" ? "left" : "top"] = pos * (this.size / this.total) + "px";
      return true;
    };
    Bar.prototype.moveTo = function(pos) {
      if (this.setPos(pos))
        this.scroll(pos, this.orientation);
    };
    var minButtonSize = 10;
    Bar.prototype.update = function(scrollSize, clientSize, barSize) {
      var sizeChanged = this.screen != clientSize || this.total != scrollSize || this.size != barSize;
      if (sizeChanged) {
        this.screen = clientSize;
        this.total = scrollSize;
        this.size = barSize;
      }
      var buttonSize = this.screen * (this.size / this.total);
      if (buttonSize < minButtonSize) {
        this.size -= minButtonSize - buttonSize;
        buttonSize = minButtonSize;
      }
      this.inner.style[this.orientation == "horizontal" ? "width" : "height"] = buttonSize + "px";
      this.setPos(this.pos, sizeChanged);
    };
    function SimpleScrollbars(cls, place, scroll) {
      this.addClass = cls;
      this.horiz = new Bar(cls, "horizontal", scroll);
      place(this.horiz.node);
      this.vert = new Bar(cls, "vertical", scroll);
      place(this.vert.node);
      this.width = null;
    }
    __name(SimpleScrollbars, "SimpleScrollbars");
    SimpleScrollbars.prototype.update = function(measure) {
      if (this.width == null) {
        var style = window.getComputedStyle ? window.getComputedStyle(this.horiz.node) : this.horiz.node.currentStyle;
        if (style)
          this.width = parseInt(style.height);
      }
      var width = this.width || 0;
      var needsH = measure.scrollWidth > measure.clientWidth + 1;
      var needsV = measure.scrollHeight > measure.clientHeight + 1;
      this.vert.node.style.display = needsV ? "block" : "none";
      this.horiz.node.style.display = needsH ? "block" : "none";
      if (needsV) {
        this.vert.update(
          measure.scrollHeight,
          measure.clientHeight,
          measure.viewHeight - (needsH ? width : 0)
        );
        this.vert.node.style.bottom = needsH ? width + "px" : "0";
      }
      if (needsH) {
        this.horiz.update(
          measure.scrollWidth,
          measure.clientWidth,
          measure.viewWidth - (needsV ? width : 0) - measure.barLeft
        );
        this.horiz.node.style.right = needsV ? width + "px" : "0";
        this.horiz.node.style.left = measure.barLeft + "px";
      }
      return { right: needsV ? width : 0, bottom: needsH ? width : 0 };
    };
    SimpleScrollbars.prototype.setScrollTop = function(pos) {
      this.vert.setPos(pos);
    };
    SimpleScrollbars.prototype.setScrollLeft = function(pos) {
      this.horiz.setPos(pos);
    };
    SimpleScrollbars.prototype.clear = function() {
      var parent = this.horiz.node.parentNode;
      parent.removeChild(this.horiz.node);
      parent.removeChild(this.vert.node);
    };
    CodeMirror.scrollbarModel.simple = function(place, scroll) {
      return new SimpleScrollbars("CodeMirror-simplescroll", place, scroll);
    };
    CodeMirror.scrollbarModel.overlay = function(place, scroll) {
      return new SimpleScrollbars("CodeMirror-overlayscroll", place, scroll);
    };
  });
})();
var simplescrollbars = simplescrollbars$2.exports;
var simplescrollbars$1 = /* @__PURE__ */ _mergeNamespaces({
  __proto__: null,
  "default": simplescrollbars
}, [simplescrollbars$2.exports]);
export { simplescrollbars$1 as s };
