var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { GraphQLNonNull, GraphQLList } from "graphql";
import { C as CodeMirror } from "./codemirror.es.js";
import { g as getTypeInfo, a as getFieldReference, b as getDirectiveReference, c as getArgumentReference, d as getEnumValueReference, e as getTypeReference } from "./SchemaReference.es.js";
import "./info-addon.es.js";
import "./index.es.js";
import "react";
import "react/jsx-runtime";
import "react-dom";
import "./forEachState.es.js";
CodeMirror.registerHelper("info", "graphql", (token, options) => {
  if (!options.schema || !token.state) {
    return;
  }
  const { kind, step } = token.state;
  const typeInfo = getTypeInfo(options.schema, token.state);
  if (kind === "Field" && step === 0 && typeInfo.fieldDef || kind === "AliasedField" && step === 2 && typeInfo.fieldDef) {
    const header = document.createElement("div");
    header.className = "CodeMirror-info-header";
    renderField(header, typeInfo, options);
    const into = document.createElement("div");
    into.appendChild(header);
    renderDescription(into, options, typeInfo.fieldDef);
    return into;
  }
  if (kind === "Directive" && step === 1 && typeInfo.directiveDef) {
    const header = document.createElement("div");
    header.className = "CodeMirror-info-header";
    renderDirective(header, typeInfo, options);
    const into = document.createElement("div");
    into.appendChild(header);
    renderDescription(into, options, typeInfo.directiveDef);
    return into;
  }
  if (kind === "Argument" && step === 0 && typeInfo.argDef) {
    const header = document.createElement("div");
    header.className = "CodeMirror-info-header";
    renderArg(header, typeInfo, options);
    const into = document.createElement("div");
    into.appendChild(header);
    renderDescription(into, options, typeInfo.argDef);
    return into;
  }
  if (kind === "EnumValue" && typeInfo.enumValue && typeInfo.enumValue.description) {
    const header = document.createElement("div");
    header.className = "CodeMirror-info-header";
    renderEnumValue(header, typeInfo, options);
    const into = document.createElement("div");
    into.appendChild(header);
    renderDescription(into, options, typeInfo.enumValue);
    return into;
  }
  if (kind === "NamedType" && typeInfo.type && typeInfo.type.description) {
    const header = document.createElement("div");
    header.className = "CodeMirror-info-header";
    renderType(header, typeInfo, options, typeInfo.type);
    const into = document.createElement("div");
    into.appendChild(header);
    renderDescription(into, options, typeInfo.type);
    return into;
  }
});
function renderField(into, typeInfo, options) {
  renderQualifiedField(into, typeInfo, options);
  renderTypeAnnotation(into, typeInfo, options, typeInfo.type);
}
__name(renderField, "renderField");
function renderQualifiedField(into, typeInfo, options) {
  var _a;
  const fieldName = ((_a = typeInfo.fieldDef) === null || _a === void 0 ? void 0 : _a.name) || "";
  text(into, fieldName, "field-name", options, getFieldReference(typeInfo));
}
__name(renderQualifiedField, "renderQualifiedField");
function renderDirective(into, typeInfo, options) {
  var _a;
  const name = "@" + (((_a = typeInfo.directiveDef) === null || _a === void 0 ? void 0 : _a.name) || "");
  text(into, name, "directive-name", options, getDirectiveReference(typeInfo));
}
__name(renderDirective, "renderDirective");
function renderArg(into, typeInfo, options) {
  var _a;
  const name = ((_a = typeInfo.argDef) === null || _a === void 0 ? void 0 : _a.name) || "";
  text(into, name, "arg-name", options, getArgumentReference(typeInfo));
  renderTypeAnnotation(into, typeInfo, options, typeInfo.inputType);
}
__name(renderArg, "renderArg");
function renderEnumValue(into, typeInfo, options) {
  var _a;
  const name = ((_a = typeInfo.enumValue) === null || _a === void 0 ? void 0 : _a.name) || "";
  renderType(into, typeInfo, options, typeInfo.inputType);
  text(into, ".");
  text(into, name, "enum-value", options, getEnumValueReference(typeInfo));
}
__name(renderEnumValue, "renderEnumValue");
function renderTypeAnnotation(into, typeInfo, options, t) {
  const typeSpan = document.createElement("span");
  typeSpan.className = "type-name-pill";
  if (t instanceof GraphQLNonNull) {
    renderType(typeSpan, typeInfo, options, t.ofType);
    text(typeSpan, "!");
  } else if (t instanceof GraphQLList) {
    text(typeSpan, "[");
    renderType(typeSpan, typeInfo, options, t.ofType);
    text(typeSpan, "]");
  } else {
    text(typeSpan, (t === null || t === void 0 ? void 0 : t.name) || "", "type-name", options, getTypeReference(typeInfo, t));
  }
  into.appendChild(typeSpan);
}
__name(renderTypeAnnotation, "renderTypeAnnotation");
function renderType(into, typeInfo, options, t) {
  if (t instanceof GraphQLNonNull) {
    renderType(into, typeInfo, options, t.ofType);
    text(into, "!");
  } else if (t instanceof GraphQLList) {
    text(into, "[");
    renderType(into, typeInfo, options, t.ofType);
    text(into, "]");
  } else {
    text(into, (t === null || t === void 0 ? void 0 : t.name) || "", "type-name", options, getTypeReference(typeInfo, t));
  }
}
__name(renderType, "renderType");
function renderDescription(into, options, def) {
  const { description } = def;
  if (description) {
    const descriptionDiv = document.createElement("div");
    descriptionDiv.className = "info-description";
    if (options.renderDescription) {
      descriptionDiv.innerHTML = options.renderDescription(description);
    } else {
      descriptionDiv.appendChild(document.createTextNode(description));
    }
    into.appendChild(descriptionDiv);
  }
  renderDeprecation(into, options, def);
}
__name(renderDescription, "renderDescription");
function renderDeprecation(into, options, def) {
  const reason = def.deprecationReason;
  if (reason) {
    const deprecationDiv = document.createElement("div");
    deprecationDiv.className = "info-deprecation";
    into.appendChild(deprecationDiv);
    const label = document.createElement("span");
    label.className = "info-deprecation-label";
    label.appendChild(document.createTextNode("Deprecated"));
    deprecationDiv.appendChild(label);
    const reasonDiv = document.createElement("div");
    reasonDiv.className = "info-deprecation-reason";
    if (options.renderDescription) {
      reasonDiv.innerHTML = options.renderDescription(reason);
    } else {
      reasonDiv.appendChild(document.createTextNode(reason));
    }
    deprecationDiv.appendChild(reasonDiv);
  }
}
__name(renderDeprecation, "renderDeprecation");
function text(into, content, className = "", options = { onClick: null }, ref = null) {
  if (className) {
    const { onClick } = options;
    let node;
    if (onClick) {
      node = document.createElement("a");
      node.href = "javascript:void 0";
      node.addEventListener("click", (e) => {
        onClick(ref, e);
      });
    } else {
      node = document.createElement("span");
    }
    node.className = className;
    node.appendChild(document.createTextNode(content));
    into.appendChild(node);
  } else {
    into.appendChild(document.createTextNode(content));
  }
}
__name(text, "text");
